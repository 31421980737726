export default [
  {
    path: "/inventory-management",
    name: "inventory-management",
    component: () =>
      import("@/views/InventoryManagement/InventoryManagementScreen.vue"),
    meta: {
      pageTitle: "Inventory Management",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Inventory Management",
          active: true,
        },
      ],
    },
  },
  {
    path: "/issues",
    name: "issues",
    component: () => import("@/views/Issues/Issue.vue"),
    meta: {
      pageTitle: "Issues",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Issues",
          active: true,
        },
      ],
    },
  },
];
