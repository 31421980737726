export default {
  // Endpoints
  loginEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/login`,
  registerEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/register`,
  logoutEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/logout`,
  checkLoginEndpoint: `${process.env.VUE_APP_SERVER_URL}/api/admin/check-login`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
  userNameKey: "userName",
  userRoleKey: "userRole",
};
