export function displayErrors(errors) {
  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];
    this.$bvToast.toast(error.msg, {
      title: "Error",
      variant: "danger",
      solid: true,
    });
  }
}
