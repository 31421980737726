export default [
  {
    path: "/job-sheets",
    name: "jobsheets-view",
    component: () => import("@/views/JobSheets/JobSheets.vue"),
    meta: {
      pageTitle: "Job Sheets",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Job Sheets",
          active: true,
        },
      ],
    },
  },
  {
    path: "/job-sheets/create",
    name: "jobsheets-create-view",
    component: () => import("@/views/JobSheets/CreateJobSheet.vue"),
    meta: {
      pageTitle: "New Job Sheet",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Job Sheets",
          to: "/job-sheets",
        },
        {
          text: "New Job Sheet",
          active: true,
        },
      ],
    },
  },
  {
    path: "/job-sheets/:orderID",
    name: "job-sheet-item-view",
    component: () => import("@/views/JobSheets/DetailedView.vue"),
    meta: {
      pageTitle: "Order Details",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Job Sheets",
          to: "/job-sheets",
        },
      ],
    },
  },
  {
    path: "/leads",
    name: "leads-view",
    component: () => import("@/views/JobSheets/Leads.vue"),
    meta: {
      pageTitle: "Draft Orders",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Leads",
          active: true,
        },
      ],
    },
  },
  {
    path: "/leads/:orderID",
    name: "leads-item-view",
    component: () => import("@/views/JobSheets/DetailedView.vue"),
    meta: {
      pageTitle: "Lead Details",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Leads",
          to: "/leads",
        },
      ],
    },
  },
];
