import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/auth/utils";

import gallery from "./routes/gallery";
import brands from "./routes/brands";
import device from "./routes/device";
import jobSheets from "./routes/job-sheets";
import issuesAndInventory from "./routes/issues-and-inventory";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: { name: "login" },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admins",
      name: "admins-list",
      component: () => import("@/views/Admin/AdminList.vue"),
      meta: {
        pageTitle: "Admins",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/",
          },
          {
            text: "Admins",
            active: true,
          },
        ],
      },
    },
    {
      path: "/auth/callback",
      name: "google-vallback",
      component: () => import("@/views/GoogleCallback.vue"),
      meta: {
        layout: "full",
        public: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        redirectIfLoggedIn: true,
      },
    },
    ...gallery,
    ...brands,
    ...device,
    ...jobSheets,
    ...issuesAndInventory,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await isUserLoggedIn();
  if (to.meta.public && !isLoggedIn) {
    next();
  } else if (to.name === "register" || (to.name === "login" && !isLoggedIn)) {
    next();
  } else if (to.name !== "login" && !isLoggedIn) {
    next("/login?redirect=" + to.path);
  } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/dashboard");
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
