export default [
  {
    path: "/brand/add",
    name: "brand-add",
    component: () => import("@/views/Brand/BrandAdd.vue"),
    meta: {
      pageTitle: "Add New Brand",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Brand",
          to: "/brand",
        },
        {
          text: "Add New",
          active: true,
        },
      ],
    },
  },
  {
    path: "/brand",
    name: "brand-view",
    component: () => import("@/views/Brand/Brand.vue"),
    meta: {
      pageTitle: "All Brands",
      breadcrumb: [
        {
          text: "Dashboard",
          to: "/",
        },
        {
          text: "Brand",
          active: true,
        },
      ],
    },
  },
  {
    path: "/brand/:brand",
    name: "brand-models-view",
    component: () => import("@/views/Brand/BrandModels.vue"),
  },
  {
    path: "/brand/:brand/:model",
    name: "brand-model-view",
    component: () => import("@/views/Brand/BrandModel.vue"),
  },
  {
    path: "/bulk-upload/models",
    name: "brand-model-bulk-upload-view",
    component: () => import("@/views/Brand/BulkUploadModels.vue"),
  },
  {
    path: "/bulk-upload/inventories",
    name: "model-inventories-bulk-upload-view",
    component: () =>
      import("@/views/InventoryManagement/BulkUploadInventories.vue"),
  },
];
