import useJwt from '@/auth/jwt/useJwt'
import Cookies from "js-cookie";

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return new Promise((resolve, reject) => {
    try {
      if (!!Cookies.get(useJwt.jwtConfig.storageTokenKeyName) === true) {
        useJwt.checklogin().then(response => {
          resolve(true)
        }).catch(error => {
          resolve(false);
        })
      } else {
        resolve(false);
      }
    } catch (error) {
      resolve(false);
    }
  })
  // return !!Cookies.get(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => Cookies.get(useJwt.jwtConfig.userNameKey);

export const getUserToken = () => Cookies.get(useJwt.jwtConfig.storageTokenKeyName);