export default [
  {
    path: "/gallery",
    name: "gallery-view",
    component: () => import("@/views/Gallery/Gallery.vue"),
    meta: {
      pageTitle: "Gallery",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
        {
          text: "Gallery",
          active: false,
        },
      ],
    },
  },
  {
    path: "/gallery/add",
    name: "gallery-add",
    component: () => import("@/views/Gallery/GalleryAdd.vue"),
    meta: {
      pageTitle: "Upload",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
        {
          text: "Gallery",
          active: true,
        },
        {
          text: "Add New",
          active: false,
        },
      ],
    },
  },
];
