export default [
  {
    path: "/device/add",
    name: "device-add",
    component: () => import("@/views/Device/DeviceAdd.vue"),
    meta: {
      pageTitle: "Add New Device",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
        {
          text: "Device",
          active: true,
        },
        {
          text: "Add New",
          active: false,
        },
      ],
    },
  },
  {
    path: "/device",
    name: "device-view",
    component: () => import("@/views/Device/Device.vue"),
    meta: {
      pageTitle: "All Devices",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
        {
          text: "Device",
          active: true,
        },
      ],
    },
  },
];
