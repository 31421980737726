import jwtDefaultConfig from "./jwtDefaultConfig";
import Cookies from "js-cookie";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  isAlreadyFetchingAccessToken = false;

  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          Cookies.remove(this.jwtConfig.storageTokenKeyName);
          Cookies.remove(this.jwtConfig.userNameKey);
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  getToken() {
    return Cookies.get(this.jwtConfig.storageTokenKeyName);
  }

  removeUserData() {
    Cookies.remove(this.jwtConfig.userNameKey);
    Cookies.remove(this.jwtConfig.storageTokenKeyName);
    return;
  }

  setToken(value) {
    Cookies.set(this.jwtConfig.storageTokenKeyName, value);
  }

  setUserName(name) {
    Cookies.set(this.jwtConfig.userNameKey, name);
  }

  setUserRole(role) {
    Cookies.set(this.jwtConfig.userRoleKey, role);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  logout() {
    return this.axiosIns.post(
      this.jwtConfig.logoutEndpoint,
      {},
      {
        headers: {
          Authorization: `${this.jwtConfig.tokenType} ${this.getToken()}`,
        },
      }
    );
  }

  checklogin() {
    return this.axiosIns.get(
      this.jwtConfig.checkLoginEndpoint,
      {},
      {
        headers: {
          Authorization: `${this.jwtConfig.tokenType} ${this.getToken()}`,
        },
      }
    );
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }
}
